import type { SessionItem } from '@refly-packages/ai-workspace-common/types';
import { ChatMessage as Message, Source, Digest, Feed, ContentDetail } from '@refly/openapi-schema';
import { safeParseJSON } from './parse';

export const buildSessionItem = (questionMsg: Message, answerMessage: Message) => {
  // 有可能 answer message 还未构建，所以要考虑兜底情况
  const session: SessionItem = {
    question: questionMsg.content || '',
    answer: answerMessage.content || '',
    sources: safeParseJSON(answerMessage.sources) || answerMessage.sources || [],
    relatedQuestions: safeParseJSON(answerMessage.relatedQuestions) || answerMessage.relatedQuestions || [],
  };

  console.log('buildSessionItem', session);

  return session;
};

export const buildSessions = (messages: Message[]) => {
  const items = [];

  for (let i = 0; i < messages.length; i += 2) {
    if (i + 1 < messages.length) {
      items.push([messages[i], messages[i + 1]]);
    } else {
      items.push([messages[i]]);
    }
  }

  const sessions = items.map((item) => buildSessionItem(item?.[0], item?.[1]));
  return sessions;
};

// 从 digest 和 feed 等 ready only aigc content 的内容构建 session
export const buildSessionsFromDigest = (aigcContent: ContentDetail) => {
  if (!(aigcContent?.title && aigcContent?.abstract)) return [];

  const session: SessionItem = {
    question: aigcContent?.title,
    answer: aigcContent?.content,
    sources: aigcContent?.inputs?.map((item) => ({
      score: 0,
      metadata: {
        ...(safeParseJSON(item?.sources)?.[0]?.medadata || {}),
        title: item?.title,
      },
      pageContent: item?.content || '', // 这里记录单个内容的总结
    })),
    relatedQuestions: [],
  };

  console.log('buildSessionsFromAIGCContent', session);
  return [session];
};

export const buildSessionsFromFeed = (aigcContent: ContentDetail) => {
  if (!(aigcContent?.title && aigcContent?.abstract)) return [];

  const session: SessionItem = {
    question: aigcContent?.title,
    answer: aigcContent?.content,
    sources: safeParseJSON(aigcContent?.sources)?.map((item: any) => ({
      score: 0,
      pageContent: aigcContent?.abstract,
      metadata: item?.medadata,
    })),
    relatedQuestions: [],
  };

  console.log('buildSessionsFromAIGCContent', session);
  return [session];
};
